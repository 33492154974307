@import "_variables";

@font-face {
	.fontface-ie(icons,icons);
}
@font-face {
	.fontface-ie(osr,OpenSans-Regular-webfont);
}
@font-face {
	.fontface-ie(ossb,OpenSans-Semibold-webfont);
}
@font-face {
	.fontface-ie(osi,OpenSans-Italic-webfont);
}
@font-face {
	.fontface-ie(ossbi,OpenSans-SemiboldItalic-webfont);
}
@font-face {
	.fontface-ie(osl,OpenSans-Light-webfont);
}
@font-face {
	.fontface-ie(cti,crimsontext-italic-webfont);
}
@font-face {
	.fontface-ie(ctsbi,crimsontext-semibolditalic-webfont);
}

html { overflow-x: auto; }
.container { overflow-x: hidden; }
hr { background-color: #ccc; }

.navigation, .members_navigation { display: block !important; }