@import "_variables";

@import "_ie";

@import "_ie8and9";

@import "_min500";
@import "_min600";
@import "_min700";
@import "_min800";
@import "_min900";
@import "_min1000";
@import "_min1100";
@import "_min1200";
@import "_min1366";

body.home_page {

	.no-svg &:after { left: auto; right: 0; width: 1366px; top: 569px; }

}

body:before { display: block; position: absolute; top: 0; left: 0; width: 100%; content:"Important Note: You are using Internet Explorer 8. We no longer actively support this browser. We recommend you use an up to date browser, such as Chrome or Firefox."; text-align: center; padding: 5px; border-bottom: 1px solid #ccc; background-color: #fff; z-index: 999; }